import { useMemo } from 'react'
import { setContext } from '@apollo/link-context'
import { createUploadLink } from 'apollo-upload-client'
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'

interface Config {
  uri: string
  authorization: string
}

export function useCreateApolloClient({ authorization, uri }: Config) {
  return useMemo(() => {
    const httpLink = createHttpLink({ uri })
    const authLink = setContext((_, { headers }) => ({
      headers: { ...(headers as object), authorization },
    }))
    return new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache({ addTypename: false }),
    })
  }, [authorization, uri])
}

export function useCreateApolloUploadClient({ authorization, uri }: Config) {
  return useMemo(() => {
    const httpLink = createUploadLink({ uri })
    const authLink = setContext((_, { headers }) => ({
      headers: { ...(headers as object), authorization },
    }))
    return new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache({ addTypename: false }),
    })
  }, [authorization, uri])
}
